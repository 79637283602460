import classNames from "classnames";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
  className?: string;
  kind: "primary" | "secondary";
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  size,
  kind,
  iconLeft,
  iconRight,
  ...props
}) => {
  const kindClass = kind === "primary" ? "btn-primary" : "btn-secondary";
  return (
    <button
      className={classNames(
        "flex items-center",
        {
          "btn-sm": size === "sm",
          btn: size === "md" || size === undefined,
          "btn-lg": size === "lg",
        },
        kindClass,
        className
      )}
      {...props}
    >
      {iconLeft && iconLeft}
      {children}
      {iconRight && iconRight}
    </button>
  );
};
